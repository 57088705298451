.Breadcrumb {
  display: flex;
  flex-direction: row;
  color: #fff;

  span {
    font-weight: normal;
    cursor: pointer;
    // white-space: nowrap;

    &:last-child {
      font-weight: bold;
    }
  }

  .MiddleArrow {
    margin: 0 10px;
    cursor: auto;
  }

  div {
    span {
      font-weight: normal;
      cursor: pointer;

      &:last-child {
        font-weight: bold;
      }
    }
  }
}

@include tablet {
  .Breadcrumb {
    flex-direction: column;
    white-space: normal;

    span {
      font-size: 1.6vw;

      &:last-child {
        font-weight: bold;
      }
    }

    .MiddleArrow {
      margin: 0 1vw;
    }

    .ViewDisplay {
      display: flex;
      flex-direction: column;
    }
  }
}

@include mobile {
  .Breadcrumb {
    flex-direction: column;
    white-space: normal;

    span {
      font-size: 2.5vw;

      &:last-child {
        font-weight: bold;
      }
    }

    .MiddleArrow {
      margin: 0 1vw;
    }

    .ViewDisplay {
      display: flex;
      flex-direction: column;
    }
  }
}
