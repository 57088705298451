@import "../mixin";

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

.header {
  background-color: #17233a;
  transition: background-color 0.5s ease;
  height: 76px;
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 160px;
  box-sizing: border-box;

  &.scrolled {
    background-color: transparent;
  }

  .left-container {
    &:hover {
      cursor: pointer;
    }
  }

  .right-container {
    font-family: "Pretendard";
    display: flex;
    flex-direction: row;
    gap: 40px;

    .lang-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      img {
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }

      .lang-choice,
      .middle {
        line-height: auto;
        color: #fff;
      }

      .lang-choice {
        &:hover {
          cursor: pointer;
        }

        &.active {
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }
    }

    .menu-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 40px;

      .menu,
      .menu-applicate {
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        &:hover {
          cursor: pointer;
        }
      }

      .menu-applicate {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5a425;
        padding: 6px 25px;
        border-radius: 5px;
        box-sizing: border-box;
      }
    }
  }

  .mobileMenu {
    display: none;
    color: white;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .modal-content {
    display: flex;
    flex-direction: column;
    background: #1b2137;
    padding: 70px 0 0 0;
    position: relative;
    width: 100%;
    height: 80%;
    box-sizing: border-box;
    align-items: center;
    color: #fff;

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: #fff;
    }

    .modal-menu-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .lang-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-bottom: 40px;
        border-bottom: 1px solid #505465;

        .left-lang {
          font-weight: bold;
          font-size: 18px;
          padding-left: 30px;
        }
        .right-lang {
          font-size: 18px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding-right: 40px;
          text-align: right;
          cursor: pointer;
        }
      }

      .menu-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 40px 0 40px 0;
        border-bottom: 1px solid #505465;

        .left {
          font-weight: bold;
          font-size: 18px;
          padding-left: 30px;
        }
        .right {
          font-size: 18px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding-right: 40px;
          text-align: right;
          cursor: pointer;
        }
      }
    }

    .modal-menu-container .menu {
      margin: 10px 0;
    }
  }
}

@include laptop {
  .header {
    .right-container {
      .lang-container {
        .lang-choice,
        .middle {
          font-size: 1vw;
        }
      }

      .menu-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2vw;

        .menu,
        .menu-applicate {
          font-size: 1vw;
        }

        .menu-applicate {
          padding: 0.5vw 1vw;
        }
      }
    }
  }
}

@include tablet {
  .header {
    padding: 0;
    &.scrolled {
      background-color: transparent;
    }

    .left-container,
    .right-container {
      display: none;
    }

    .mobileMenu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      .mobileInner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
      }

      .menuBtn {
        position: absolute;
        right: 30px;
      }
    }
  }

  .expand {
    transition: background-color 0.5s ease;
    position: fixed;
    display: flex;
    z-index: 999;
    top: 76px;
    right: 0;
    width: 100%;
    height: auto;
    background-color: #1b2137;
    padding: 30px 40px;
    color: #fff;
    flex-direction: column;
    box-sizing: border-box;

    &.scrolled {
      background-color: #1b2137;
    }

    .Links {
      display: flex;
      flex-direction: column;
      gap: 1.5vw;
      margin-bottom: 30px;
      text-align: left;
      span {
        font-size: 2.7vw;
        font-weight: bold;
      }
    }

    .Lang {
      display: flex;
      flex-direction: row;
      text-align: left;
      gap: 15px;
      font-weight: 300;
      font-size: 2.7vw;

      img {
        vertical-align: middle;
        width: 2vw;
        margin-right: 5px;
      }

      span {
        &.active {
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }

      .middle {
        margin: 0 10px 0 10px;
      }
    }
  }
}

@include mobile {
  .header {
    padding: 0;
    &.scrolled {
      background-color: #17233a;
    }

    .left-container,
    .right-container {
      display: none;
    }

    .mobileMenu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      cursor: pointer;
      .mobileInner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
      }

      .menuBtn {
        position: absolute;
        right: 30px;
      }
    }
  }

  .expand {
    transition: background-color 0.5s ease;
    position: fixed;
    display: flex;
    z-index: 999;
    top: 76px;
    right: 0;
    width: 100%;
    height: auto;
    background-color: #1b2137;
    padding: 7vw 10vw;
    color: #fff;
    flex-direction: column;
    box-sizing: border-box;

    &.scrolled {
      background-color: #1b2137;
    }

    .Links {
      display: flex;
      flex-direction: column;
      gap: 1.5vw;
      margin-bottom: 5vw;
      text-align: left;
      span {
        font-size: 2.7vw;
        font-weight: bold;
      }
    }

    .Lang {
      display: row;
      flex-direction: column;
      text-align: left;
      gap: 1.5vw;
      font-weight: 300;
      font-size: 2.7vw;
      img {
        vertical-align: middle;
        width: 2.5vw;
        margin-right: 5px;
      }

      span {
        &.active {
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }

      .Middle {
        margin: 0 10px 0 10px;
      }
    }
  }
}
