.License {
  height: auto;
  padding: 100px 150px;
  // width: 100vw;
  background-color: #17233a;

  .InnerContainer {
    display: flex;
    flex-direction: column;
    // padding: 100px 160px;
    box-sizing: border-box;

    .Breadcrumb {
      margin-left: 1vw;
    }

    .SearchContainer {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin: 31px 0 30px 0;

      .SearchBar {
        display: flex;
        flex-direction: row;
        width: 250px;
        border-bottom: 1px solid #fff;
        align-items: center;

        img {
          height: 18px;
          margin-right: 10px;
        }

        input {
          background-color: transparent;
          border: none;
          margin-bottom: 5px;
          font-size: 14px;
          color: #fff;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .LicenseContainer {
      display: flex;
      flex-direction: column;

      .LicenseTitle {
        font-size: 32px;
        font-weight: bold;
        padding-left: 10px;
        margin-bottom: 20px;
        color: #e0edff;
        white-space: nowrap;
      }

      @include LicenseStyle;
    }
  }
}

@include laptop {
  .License {
    height: auto;
    padding: 100px 200px;

    .InnerContainer {
      .SearchContainer {
        .SearchBar {
          img {
            height: 18px;
            margin-right: 10px;
          }

          input {
            background-color: transparent;
            border: none;
            margin-bottom: 5px;
            font-size: 14px;
            color: #fff;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .LicenseContainer {
        display: flex;
        flex-direction: column;

        .LicenseTitle {
          font-size: 2.2vw;
          padding-left: 1vw;
          font-weight: bold;
          margin-bottom: 1vw;
          color: #e0edff;
          white-space: nowrap;
        }

        .Licenses {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 10px;

          .LicenseBox {
            width: 90%;
            margin-right: 10px;
            height: 10vw;
            min-width: 0;
            min-height: 0;

            .Title {
              font-size: 1vw;
            }
            .Desc {
              font-size: 1vw;
            }
          }
          .EmptyBox {
            display: none;
          }
        }
      }
    }
  }
}

@include tablet {
  .License {
    padding: 0;

    .InnerContainer {
      padding: 100px 100px;

      .Breadcrumb {
        margin-left: 2vw;
      }
      .SearchContainer {
        display: flex;
        width: 100%;

        justify-content: center;
        margin: 50px 0 50px 0;

        .SearchBar {
          display: flex;
          flex-direction: row;
          width: 95%;
          border-bottom: 1px solid #fff;
          align-items: center;

          img {
            height: 2.5vw;
            margin-right: 10px;
          }

          input {
            background-color: transparent;
            border: none;
            margin-bottom: 5px;
            font-size: 1.6vw;
            color: #fff;
            height: 1.8vw;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .LicenseContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .LicenseTitle {
          width: 100%;
          font-size: 3.2vw;
          font-weight: bold;
          text-align: left;
          padding-left: 4vw;
          margin-bottom: 1vw;
          color: #e0edff;
          white-space: nowrap;
        }

        .Licenses {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 10px;
          gap: 2vw;
          width: 100%;

          .LicenseBox {
            width: 100%;
            height: 18vw;
            min-width: 0;
            min-height: 0;

            .Title {
              font-size: 2vw;
            }
            .Desc {
              font-size: 1.6vw;
            }
          }
          .EmptyBox {
            display: none;
          }
        }
      }
    }
  }
}

@include mobile {
  .License {
    padding: 0;

    .InnerContainer {
      padding: 100px 20px 100px 10px;

      .Breadcrumb {
        margin-left: 12vw;
      }

      .SearchContainer {
        display: flex;
        width: 100%;

        justify-content: center;
        margin: 50px 0 50px 0;

        .SearchBar {
          display: flex;
          flex-direction: row;
          width: 70vw;
          border-bottom: 1px solid #fff;
          align-items: center;

          img {
            height: 2.5vw;
            margin-right: 10px;
          }

          input {
            background-color: transparent;
            border: none;
            margin-bottom: 5px;
            font-size: 1.6vw;
            color: #fff;
            height: 1.8vw;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .LicenseContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .LicenseTitle {
          width: 100%;
          font-size: 3.2vw;
          font-weight: bold;
          text-align: left;
          padding-left: 13vw;
          margin-bottom: 2vw;
          color: #e0edff;
          white-space: nowrap;
          box-sizing: border-box;
        }

        .Licenses {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 2.5vw;
          width: 80%;

          .LicenseBox {
            width: 100%;
            height: 18vw;
            min-width: 0;
            min-height: 0;

            .Title {
              font-size: 2vw;
            }
            .Desc {
              font-size: 1.6vw;
            }
          }
          .EmptyBox {
            display: none;
          }
        }
      }
    }
  }
}
