.CourseSection {
  width: 100vw;
  height: 100vh;
  background-color: #0c1228;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .InnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .TitleContainer {
      display: flex;
      justify-content: center;
      color: #fff;
      margin-bottom: 60px;

      span {
        font-size: 36px;
        font-weight: 500;
      }
    }

    .CourseContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 50px;
      grid-row-gap: 25px;

      .CourseBox {
        .Inner {
          display: flex;
          flex-direction: row;
          cursor: pointer;
          box-sizing: border-box;
          border: 1px solid transparent;
          &:hover {
            border: 1px solid #505464;
            border-radius: 5px;
          }

          .Thumbnail {
            width: 283px;
            height: 160px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .Text {
            display: flex;
            flex-direction: column;
            width: 272px;
            height: 160px;
            background-color: #24293d;
            border: 1px solid #505464;
            box-sizing: border-box;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            justify-content: center;
            padding-left: 25px;
            gap: 23px;

            &.enFont {
              .Title {
                font-size: 20px;
                font-weight: bold;
              }

              .Description {
                font-size: 14px;
              }
            }

            .Title,
            .Description {
              color: #e0edff;
            }

            .Title {
              font-size: 24px;
              font-weight: bold;
            }

            .Description {
              font-size: 15px;
            }
          }
        }
      }
    }

    .GoToCourse {
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      background-color: #f5a425;
      border-radius: 5px;
      padding: 16px 56px;
      margin-top: 50px;
      font-size: 24px;
      font-weight: bold;
      color: #fff;
    }
  }
}

@include laptop {
  .CourseSection {
    .InnerContainer {
      .TitleContainer {
        margin-bottom: 2vw;

        span {
          font-size: 2vw;
        }
      }

      .CourseContainer {
        grid-column-gap: 2vw;
        grid-row-gap: 1.5vw;

        .CourseBox {
          .Inner {
            .Thumbnail {
              width: 17vw;
              height: 10vw;
            }

            .Text {
              width: 17vw;
              height: 10vw;
              padding-left: 1.5vw;
              gap: 1vw;

              &.enFont {
                .Title {
                  font-size: 1.2vw;
                }

                .Description {
                  font-size: 1vw;
                }
              }

              .Title {
                font-size: 1.3vw;
              }

              .Description {
                font-size: 1.1vw;
              }
            }
          }
        }
      }

      .GoToCourse {
        font-size: 1.4vw;
        margin-top: 2.5vw;
        padding: 0.8vw 1.6vw;
      }
    }
  }
}

@include tablet {
  .CourseSection {
    padding: 50px 0;
    height: auto;

    .InnerContainer {
      width: 100%;

      .TitleContainer {
        margin-bottom: 30px;

        span {
          font-size: 3vw;
        }
      }

      .CourseContainer {
        grid-template-columns: none;
        gap: 2vw;

        .CourseBox {
          .Inner {
            .Thumbnail {
              width: 17vw;
              height: 10vw;
            }

            .Text {
              width: 17vw;
              height: 10vw;
              padding-left: 2vw;
              gap: 10px;

              &.enFont {
                .Title {
                  font-size: 1.8vw;
                }

                .Description {
                  font-size: 1.4vw;
                }
              }

              .Title {
                font-size: 14px;
              }

              .Description {
                font-size: 12px;
              }
            }
          }
        }
      }

      .GoToCourse {
        font-size: 16px;
        margin-top: 5vw;
        padding: 1vw 2vw;
      }
    }
  }
}

@include mobile {
  .CourseSection {
    padding: 50px 0;
    height: auto;

    .InnerContainer {
      width: 100%;

      .TitleContainer {
        margin-bottom: 30px;

        span {
          font-size: 3vw;
        }
      }

      .CourseContainer {
        grid-template-columns: none;
        gap: 0.1vw;

        .CourseBox {
          .Inner {
            width: 70vw;
            height: 22vw;
            margin: 10px 0;

            .Thumbnail {
              width: 50%;
              height: 100%;
            }

            .Text {
              width: 50%;
              height: 100%;
              padding-left: 3vw;
              gap: 2vw;
              &.enFont {
                .Title {
                  font-size: 2.4vw;
                }

                .Description {
                  font-size: 2vw;
                }
              }
              .Title {
                font-size: 2.5vw;
              }

              .Description {
                font-size: 2.2vw;
              }
            }
          }
        }
      }

      .GoToCourse {
        font-size: 1.8vw;
        padding: 1vw 2vw;
        margin-top: 5vw;
      }
    }
  }
}
