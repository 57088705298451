.GoToApplicate {
  width: 100%;
  height: 211px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;

  span {
    font-size: 30px;
    font-weight: bold;
    color: #17233a;
  }

  .BtnBox {
    padding: 15px 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #17233a;
    border-radius: 10px;
    cursor: pointer;

    span {
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      white-space: nowrap;
    }
  }
}

@include laptop {
  .GoToApplicate {
    height: 12vw;

    span {
      font-size: 1.6vw;
    }

    .BtnBox {
      padding: 1vw 2vw;

      span {
        font-size: 1.6vw;
      }
    }
  }
}

@include tablet {
  .GoToApplicate {
    height: 12vw;
    gap: 1.8vw;
    span {
      font-size: 1.6vw;
    }

    .BtnBox {
      padding: 1vw 2vw;

      span {
        font-size: 1.6vw;
      }
    }
  }
}

@include mobile {
  .GoToApplicate {
    height: 12vw;
    gap: 1.5vw;

    span {
      font-size: 1.6vw;
    }

    .BtnBox {
      padding: 1vw 2vw;

      span {
        font-size: 1.6vw;
      }
    }
  }
}
