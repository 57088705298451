.View {
  height: 100vh;
  padding: 100px 200px;
  box-sizing: border-box;
  background-color: #0c1228;

  .InnerContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: calc(100% - 290px);
    transition: width 0.3s ease;

    &.collapsed {
      width: calc(100% - 30px);
    }

    .TopContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 60px;

      &.mobile {
        display: none;
      }

      .Title {
        color: #e0edff;
        text-align: center;

        .MainTitle {
          font-size: 32px;
          font-weight: bold;
          margin-right: 40px;
        }

        .Desc {
          font-size: 20px;
        }
      }

      .Buttons {
        display: flex;
        flex-direction: row;
        color: #fff;
        padding-top: 20px;

        span {
          margin: 0 10px;
        }

        div {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
          img {
            margin: 0 5px;
          }
        }
      }
    }

    .Video {
      margin-top: 10px;
      background-color: #000;
      width: 100%;
      height: 70vh;
    }
  }
}

@include laptop {
  .View {
    height: auto;
    padding: 100px 10vw;
    box-sizing: border-box;
    background-color: #0c1228;

    .InnerContainer {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: calc(100% - 15vw);
      transition: width 0.3s ease;

      &.collapsed {
        width: calc(100% - 30px);
      }

      .TopContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2vw;

        &.mobile {
          display: none;
        }

        .Title {
          color: #e0edff;
          text-align: center;

          .MainTitle {
            font-size: 32px;
            font-weight: bold;
            margin-right: 40px;
          }

          .Desc {
            font-size: 18px;
          }
        }

        .Buttons {
          display: flex;
          flex-direction: row;
          color: #fff;
          padding-top: 20px;

          span {
            margin: 0 10px;
          }

          div {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
            img {
              margin: 0 5px;
            }
          }
        }
      }

      .Video {
        margin-top: 10px;

        background-color: #000;
        width: 100%;
        height: 40vw;
      }
    }
  }
}

@include tablet {
  .View {
    height: auto;
    padding: 100px 50px;
    min-height: 90vh;

    .InnerContainer {
      width: 100%;

      .TopContainer {
        display: none;
        &.mobile {
          display: flex;
          flex-direction: column;
          margin: 2vw 0 0 0;
        }
        .Title {
          display: flex;
          flex-direction: column;
          text-align: left;

          .MainTitle {
            font-size: 2.2vw;
            font-weight: bold;
          }

          .Desc {
            margin-top: 1vw;
            font-size: 1.5vw;
          }
        }

        .Buttons {
          padding-top: 3vw;
          justify-content: center;
          font-size: 1.5vw;

          span {
            margin: 0 2vw;
          }

          div {
            img {
              margin: 0 1.2vw;
              width: 1.5vw;
              vertical-align: bottom;
            }
          }
        }
      }

      .Video {
        margin-top: 4vw;
        width: 100%;
        height: 50vw;
      }
    }
  }
}

@include mobile {
  .View {
    height: auto;
    padding: 100px 20px;
    min-height: calc(100vh - 30vw);

    .InnerContainer {
      width: 100%;
      min-height: 70vh;

      .TopContainer {
        display: none;
        &.mobile {
          display: flex;
          flex-direction: column;
          margin: 2vw 0 0 0;
        }
        .Title {
          display: flex;
          flex-direction: column;
          text-align: left;

          .MainTitle {
            font-size: 2.2vw;
            font-weight: bold;
          }

          .Desc {
            margin-top: 1vw;
            font-size: 1.5vw;
          }
        }

        .Buttons {
          padding-top: 3vw;
          justify-content: center;
          font-size: 1.5vw;

          span {
            margin: 0 2vw;
          }

          div {
            img {
              margin: 0 1.2vw;
              width: 1.5vw;
              vertical-align: bottom;
            }
          }
        }
      }

      .Video {
        margin-top: 10vw;
        width: 100%;
        height: 50vw;
      }
    }
  }
}
