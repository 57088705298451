.Sidebar {
  display: flex;
  flex-direction: row;
  position: fixed;
  right: 0;
  bottom: 0;
  height: calc(100% - 76px);
  transition: width 0.3s ease;

  &.open {
    width: 320px;
  }

  &.collapsed {
    width: 30px;
  }

  .BtnDiv {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      width: 15px;
      height: 30px;
      margin-right: 15px;
      cursor: pointer;
    }
  }

  .Inner {
    width: 320px;
    background-color: #17233a;
    border-top-left-radius: 10px;
    color: #fff;
    border: 1px solid #2e394d;
    box-sizing: border-box;

    .Title {
      font-size: 20px;
      font-weight: bold;
      padding: 14px 30px;
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid #2e394d;
    }

    .Courses {
      display: flex;
      flex-direction: column;
      padding: 20px 15px 15px 30px;
      box-sizing: border-box;
      border-bottom: 1px solid #2e394d;
      gap: 17px;
      cursor: pointer;

      &.now {
        background-color: rgba(#000, 0.2);
      }

      .CourseDesc {
        display: flex;
        flex-direction: row;

        .Time {
          color: rgba(#fff, 0.7);
          font-weight: 300;
        }

        .Watching {
          margin-left: 25px;

          img {
            margin-right: 10px;
          }

          span {
            font-size: 12px;
            color: #f5a425;
          }
        }
      }
    }

    .Btn {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      margin-top: 15px;

      .GoToList {
        cursor: pointer;
        background-color: #f5a425;
        padding: 10px 20px;
        border-radius: 5px;
        box-sizing: border-box;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
}

@include laptop {
  .Sidebar {
    display: flex;
    flex-direction: row;
    position: fixed;
    right: 0;
    bottom: 0;
    height: calc(100% - 76px);
    transition: width 0.3s ease;

    &.open {
      width: 20vw;
    }

    &.collapsed {
      width: 30px;
    }

    .BtnDiv {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        width: 15px;
        height: 30px;
        margin-right: 15px;
        cursor: pointer;
      }
    }

    .Inner {
      width: 20vw;

      .Title {
        font-size: 1.3vw;
        font-weight: bold;
        padding: 14px 30px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #2e394d;
      }

      .Courses {
        display: flex;
        flex-direction: column;
        padding: 1vw 0 1vw 2vw;
        box-sizing: border-box;
        border-bottom: 1px solid #2e394d;
        gap: 1vw;
        cursor: pointer;
        font-size: 1vw;

        &.now {
          background-color: rgba(#000, 0.2);
        }

        .CourseDesc {
          display: flex;
          flex-direction: row;

          .Time {
            color: rgba(#fff, 0.7);
            font-weight: 300;
          }

          .Watching {
            margin-left: 2vw;
            span {
              font-size: 1vw;
              color: #f5a425;
            }
          }
        }
      }

      .Btn {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        margin-top: 15px;

        .GoToList {
          cursor: pointer;
          background-color: #f5a425;
          padding: 0.5vw 0.8vw;
          border-radius: 5px;
          box-sizing: border-box;
          font-size: 1vw;
          font-weight: bold;
        }
      }
    }
  }
}

@include tablet {
  .Sidebar {
    display: none;
  }
}

@include mobile {
  .Sidebar {
    display: none;
  }
}
