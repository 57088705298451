.ScrollButton {
  position: fixed;
  height: 100vh;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: 0;
  left: 100px;

  .Wrapper {
    display: flex;
    position: relative;
  }
  .ScrollStatusWrapper {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    z-index: 999;
    border-radius: 50px;
    border: 3px #f5a425 solid;
    width: 30px;
    height: 111px;
    box-sizing: border-box;
  }

  .ActiveBtn {
    cursor: pointer;
    width: 11px;
    height: 13px;
    border-radius: 50%;
    border: 1px #f5a425 solid;
    background-color: #f5a425;
  }

  .Btn {
    cursor: pointer;
    width: 14px;
    height: 15px;
    border-radius: 50%;
    scale: 0.3;
    background-color: #f5a425;
  }

  .Title {
    position: absolute;
    top: 2px;
    left: -25px;
    color: #f5a425;
    font-weight: 400;
    transform: rotate(-90deg) translate(-100%);
    transform-origin: top left;
  }
}

@include tablet {
  .ScrollButton {
    display: none;
  }
}

@include mobile{
  .ScrollButton{
    display: none;
  }
}
