.LicenseDetail {
  height: auto;
  width: 100vw;
  background-color: #17233a;
  color: #e0edff;
  .InnerContainer {
    display: flex;
    flex-direction: column;
    padding: 100px 160px;

    .TopTitle {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid #505464;
      padding-bottom: 14px;
      margin-top: 56px;
      margin-bottom: 30px;
      span {
        font-size: 32px;
        font-weight: bold;
      }

      .ListBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: bold;
        background-color: #f5a425;
        box-shadow: 0px 1px 1px 1px #000;
        cursor: pointer;
      }
    }

    .Body {
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;
      .Summary {
        font-size: 32px;
        margin-bottom: 20px;
      }

      .Desc {
        font-size: 20px;
        line-height: 25px;
      }

      table {
        font-size: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-collapse: collapse;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        margin-top: 40px;
        color: #fff;

        td {
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          height: 35px;
        }
        .LeftCell {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #454f61;
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          width: 268px;
        }
        .RightCell {
          width: 100%;
          padding-left: 10px;
        }
      }
    }

    .Relate {
      .SubTitle {
        font-size: 18px;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 10px;
        margin-bottom: 10px;
      }

      .CourseLists {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .CourseContainer {
          height: 251px;
          width: 273px;
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          .ThumbnailBox {
            width: 100%;
            height: 155px;
            background-color: gray;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          .DescBox {
            width: 100%;
            height: 96px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px 20px;
            position: relative;
            box-sizing: border-box;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            color: #fff;
            background-color: rgba($color: #fff, $alpha: 0.1);
            border: 1px solid rgba($color: #fff, $alpha: 0.2);

            .DescTitle {
              font-size: 16px;
            }

            .DescDesc {
              font-size: 12px;
            }

            .DescBtn {
              position: absolute;
              bottom: 10px;
              right: 20px;
              background-color: #f5a425;
              border-radius: 3px;
              cursor: pointer;
              padding: 5px 10px;
              font-size: 12px;
            }
          }
        }
      }

      @include LicenseStyle;
    }
  }
}

@include laptop {
  .LicenseDetail {
    height: auto;
    width: 100vw;
    background-color: #17233a;
    color: #e0edff;
    .InnerContainer {
      display: flex;
      flex-direction: column;
      padding: 100px 160px;

      .TopTitle {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid #505464;
        padding-bottom: 14px;
        margin-top: 56px;
        margin-bottom: 30px;
        span {
          font-size: 2vw;
          font-weight: bold;
        }

        .ListBtn {
          padding: 0.5vw 1vw;
        }
      }

      .Body {
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        .Summary {
          font-size: 32px;
          margin-bottom: 20px;
        }

        .Desc {
          font-size: 1.3vw;
        }

        table {
          font-size: 1.3vw;
          border-top: 1px solid rgba(255, 255, 255, 0.2);
          border-collapse: collapse;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          margin-top: 40px;
          color: #fff;

          td {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            height: 35px;
          }
          .LeftCell {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #454f61;
            border-right: 1px solid rgba(255, 255, 255, 0.2);
            width: 268px;
          }
          .RightCell {
            width: 100%;
            padding-left: 10px;
          }
        }
      }

      .Relate {
        .SubTitle {
          font-size: 18px;
          width: 100%;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          padding-bottom: 10px;
          margin-bottom: 10px;
        }

        .CourseLists {
          .CourseContainer {
            height: 251px;
            width: 273px;
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .ThumbnailBox {
              width: 100%;
              height: 155px;
              background-color: gray;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            .DescBox {
              width: 100%;
              height: 96px;
              display: flex;
              flex-direction: column;
              gap: 22px;
              padding-left: 15px;
              justify-content: center;
              position: relative;
              box-sizing: border-box;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              color: #fff;
              background-color: rgba($color: #fff, $alpha: 0.1);
              border: 1px solid rgba($color: #fff, $alpha: 0.2);

              .DescTitle {
                font-size: 16px;
              }

              .DescDesc {
                font-size: 12px;
              }

              .DescBtn {
                position: absolute;
                bottom: 10px;
                right: 20px;
              }
            }
          }
        }
        .Licenses {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-column-gap: 0vw;
          grid-row-gap: 1vw;
          overflow: hidden;
          margin: 0 0 10vw 0;

          .LicenseBox {
            width: 18vw;
            height: 11vw;
            min-width: 0;
            min-height: 0;

            .Title {
              font-size: 1.2vw;
            }
            .Desc {
              font-size: 1vw;
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .LicenseDetail {
    .InnerContainer {
      padding: 100px 50px;

      .TopTitle {
        padding-bottom: 10px;
        span {
          font-size: 2.2vw;
          font-weight: bold;
        }

        .ListBtn {
          padding: 1vw 1vw;
          font-size: 1.5vw;
        }
      }

      .Body {
        display: flex;
        flex-direction: column;
        margin-bottom: 8vw;
        .Summary {
          font-size: 2vw;
          margin-bottom: 2vw;
        }

        .Desc {
          font-size: 1.4vw;
          line-height: 1.7vw;
        }

        table {
          font-size: 1.4vw;
          margin-top: 2vw;

          td {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            height: 35px;
          }
          .LeftCell {
            width: 15vw;
          }
          .RightCell {
            width: 100%;
            padding-left: 10px;
          }
        }
      }

      .Relate {
        .SubTitle {
          font-size: 2vw;
          width: 100%;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          padding-bottom: 10px;
          margin-bottom: 10px;
        }

        .Licenses {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-column-gap: 0vw;
          grid-row-gap: 1vw;
          overflow: hidden;
          margin: 0;
          gap: 1vw;

          .LicenseOuter {
            .LicenseBox {
              width: 18vw;
              height: 13vw;
              min-width: 0;
              min-height: 0;
              margin: 0;

              .Title {
                font-size: 1.4vw;
              }
              .Desc {
                font-size: 1.2vw;
              }
            }
          }
        }

        .CourseLists {
          gap: 1vw;
          .CourseContainer {
            height: auto;
            width: auto;
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .ThumbnailBox {
              width: 18vw;
              height: 10vw;
              background-color: gray;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            .DescBox {
              width: 18vw;
              height: 10vw;
              display: flex;
              flex-direction: column;
              gap: 0.5vw;
              padding: 1.5vw 1.5vw;
              justify-content: start;
              position: relative;
              box-sizing: border-box;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              color: #fff;
              background-color: rgba($color: #fff, $alpha: 0.1);
              border: 1px solid rgba($color: #fff, $alpha: 0.2);

              .DescTitle {
                font-size: 1.4vw;
              }

              .DescDesc {
                font-size: 1.1vw;
              }

              .DescBtn {
                position: absolute;
                bottom: 1vw;
                right: 1vw;
                font-size: 1vw;
                padding: 0.5vw 0.5vw;
                background-color: #f5a425;
                border-radius: 3px;
                cursor: pointer;
              }
            }
          }
        }

        .LicenseTitle {
          font-size: 4vw;
          font-weight: bold;
          margin-bottom: 30px;
          color: #e0edff;
          white-space: nowrap;
        }
      }
    }
  }
}

@include mobile {
  .LicenseDetail {
    .InnerContainer {
      padding: 100px 20px;

      .TopTitle {
        padding-bottom: 10px;
        span {
          font-size: 3vw;
          font-weight: bold;
        }

        .ListBtn {
          font-size: 2vw;
          padding: 1vw 1vw;
        }
      }

      .Body {
        display: flex;
        flex-direction: column;
        margin-bottom: 30vw;
        .Summary {
          font-size: 2.2vw;
          margin-bottom: 4vw;
        }

        .Desc {
          font-size: 1.6vw;
          line-height: 2vw;
        }

        table {
          font-size: 1.6vw;
          margin-top: 4vw;

          td {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            height: 4vw;
          }
          .LeftCell {
            width: 15vw;
          }
          .RightCell {
            width: 100%;
            padding-left: 10px;
          }
        }
      }

      .Relate {
        .SubTitle {
          font-size: 2.8vw;
          width: 100%;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          padding-bottom: 10px;
          margin-bottom: 10px;
        }

        .Licenses {
          display: grid;
          grid-template-columns: 50% 50%;
          grid-column-gap: 0px;
          grid-row-gap: 0px;

          .LicenseOuter {
            display: flex;
            justify-content: center;
            width: 100%;

            .LicenseBox {
              width: 80%;
              height: 20vw;
              min-width: 0;
              min-height: 0;
              margin: 0 0 3vw 0;
              padding: 1vw 1vw;

              .Title {
                font-size: 2vw;
              }
              .Desc {
                font-size: 1.5vw;
              }
            }
          }
          .EmptyBox {
            display: none;
          }
        }

        .CourseLists {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;

          .CourseContainer {
            display: flex;
            align-items: center;
            height: auto;
            width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .ThumbnailBox {
              width: 100%;
              height: 30vw;
              background-color: gray;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            .DescBox {
              width: 100%;
              height: 30vw;
              display: flex;
              flex-direction: column;
              gap: 1vw;
              padding: 3vw;
              justify-content: start;
              position: relative;
              box-sizing: border-box;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              color: #fff;
              background-color: rgba($color: #fff, $alpha: 0.1);
              border: 1px solid rgba($color: #fff, $alpha: 0.2);

              .DescTitle {
                font-size: 3.5vw;
              }

              .DescDesc {
                font-size: 2.5vw;
              }

              .DescBtn {
                position: absolute;
                bottom: 3vw;
                right: 3vw;
                font-size: 3vw;
                padding: 1vw 2vw;
                background-color: #f5a425;
                border-radius: 3px;
                cursor: pointer;
              }
            }
          }
        }

        .LicenseTitle {
          font-size: 4vw;
          font-weight: bold;
          margin-bottom: 30px;
          color: #e0edff;
          white-space: nowrap;
        }
      }
    }
  }
}
