.Footer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 462px;
  padding: 83px 160px 0 160px;
  box-sizing: border-box;
  background-color: #0c1228;
  color: #fff;

  .LogoContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .MitiBox {
      .MitiLogo {
        width: 90px;
      }
      span {
        margin-left: 25px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
      }
    }

    .YoutubeLogo {
      width: 64px;
      height: 51px;
      margin-top: 25px;
      cursor: pointer;
    }
  }

  .Description {
    margin-top: 34px;
    gap: 8px;
    span {
      font-size: 18px;
      display: block;
      margin-bottom: 5px;
    }

    .Policy,
    .Copyright {
      font-weight: bold;
    }

    .Policy {
      display: flex;
      flex-direction: row;
      margin-top: 24px;

      .Privacy,
      .EmailReject {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .Middle {
        padding: 0 5px 0 5px;
      }
    }

    .Copyright {
      margin-top: 47px;
    }
  }
}

@include laptop {
  .Footer {
    height: auto;
    padding: 50px 160px 50px 160px;

    .LogoContainer {
      .MitiBox {
        .MitiLogo {
          width: 40px;
        }
        span {
          margin-left: 1vw;
          font-size: 1vw;
        }
      }

      .YoutubeLogo {
        width: 40px;
        height: auto;
        margin-top: 10px;
        margin-right: 10px;
      }
    }

    .Description {
      margin-top: 20px;

      span {
        font-size: 1vw;
        display: block;
      }

      .Policy,
      .Copyright {
        font-weight: bold;
      }

      .Policy {
        display: flex;
        flex-direction: row;
        margin-top: 1vw;

        .Privacy,
        .EmailReject {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .Middle {
          padding: 0 5px 0 5px;
        }
      }

      .Copyright {
        margin-top: 1.4vw;
      }
    }
  }
}

@include tablet {
  .Footer {
    height: 30vw;
    padding: 5vw 10vw 5vw 10vw;

    .LogoContainer {
      .MitiBox {
        .MitiLogo {
          width: 5vw;
        }
        span {
          margin-left: 1vw;
          font-size: 1.4vw;
        }
      }

      .YoutubeLogo {
        width: 5vw;
        height: auto;
        margin-top: 10px;
        margin-right: 10px;
      }
    }

    .Description {
      margin-top: 20px;

      span {
        font-size: 1.4vw;
        display: block;
        margin-bottom: 0.3vw;
      }

      .Policy,
      .Copyright {
        font-weight: bold;
      }

      .Policy {
        display: flex;
        flex-direction: row;
        margin-top: 1vw;

        .Privacy,
        .EmailReject {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .Middle {
          padding: 0 5px 0 5px;
        }
      }

      .Copyright {
        margin-top: 1.4vw;
      }
    }
  }
}

@include mobile {
  .Footer {
    height: 35vw;
    padding: 5vw 10vw 5vw 10vw;

    .LogoContainer {
      .MitiBox {
        .MitiLogo {
          width: 6vw;
        }
        span {
          margin-left: 2vw;
          font-size: 2vw;
        }
      }

      .YoutubeLogo {
        width: 6vw;
        height: auto;
        margin-top: 10px;
        margin-right: 10px;
      }
    }

    .Description {
      margin-top: 2vw;

      span {
        font-size: 1.6vw;
        display: block;
        margin-bottom: 0.3vw;
      }

      .Policy,
      .Copyright {
        font-weight: bold;
      }

      .Policy {
        display: flex;
        flex-direction: row;
        margin-top: 2vw;

        .Privacy,
        .EmailReject {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .Middle {
          padding: 0 5px 0 5px;
        }
      }

      .Copyright {
        margin-top: 3vw;
      }
    }
  }
}
