.CourseDetail {
  height: auto;
  width: 100vw;
  background-color: #0c1228;
  .InnerContainer {
    display: flex;
    flex-direction: column;
    padding: 100px 200px;
    box-sizing: border-box;
    color: #fff;

    .wrap {
      display: flex;
      margin-top: 50px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .LeftSide {
        display: flex;
        flex-direction: row;
        .LeftInner {
          display: flex;
          flex-direction: row;
          color: #e0edff;

          .Numbering {
            font-size: 32px;
            font-weight: bold;
            margin-right: 34px;
          }

          .Desc {
            display: flex;
            flex-direction: column;

            .Title {
              font-size: 32px;
              font-weight: bold;
            }
            .SubDesc {
              font-size: 18px;
            }
          }
        }
      }

      .RightSide {
        .SubjTitle {
          display: flex;
          align-items: center;
          width: 743px;
          font-size: 20px;
          height: 54px;
          background-color: rgba(#fff, 0.2);
          padding: 0 20px;
          box-sizing: border-box;
          border-radius: 5px;
          margin: 10px 0;
        }

        .RowLine {
          width: 90%;
          margin: 10px 0 10px 5%;
          height: 1px;
          background-color: rgba(#fff, 0.5);
        }

        .SubjCourse {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 58px;
          height: 50px;

          .NumberingDiv {
            display: flex;
            height: 100%;
            align-items: center;

            .Numbering {
              margin-right: 15px;
            }
          }

          .SubjCourseDetail {
            display: flex;
            height: 100%;
            align-items: center;
            //flex-direction: row;

            .btn {
              margin-left: 15px;
              padding: 5px 10px;
              background-color: #f5a425;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@include laptop {
  .CourseDetail {
    height: auto;
    width: 100vw;

    .InnerContainer {
      display: flex;
      flex-direction: column;
      padding: 100px 200px;
      box-sizing: border-box;
      color: #fff;

      .wrap {
        display: flex;
        margin-top: 50px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .LeftSide {
          display: flex;
          flex-direction: row;
          .LeftInner {
            display: flex;
            flex-direction: row;
            color: #e0edff;

            .Numbering {
              font-size: 1.8vw;
              font-weight: bold;
              margin-right: 1.5vw;
            }

            .Desc {
              display: flex;
              flex-direction: column;

              .Title {
                font-size: 1.8vw;
                font-weight: bold;
              }
              .SubDesc {
                font-size: 1.2vw;
              }
            }
          }
        }

        .RightSide {
          .SubjTitle {
            display: flex;
            align-items: center;
            width: 45vw;
            font-size: 1.2vw;
            height: 3.5vw;
            background-color: rgba(#fff, 0.2);
            padding: 0 1.5vw;
            box-sizing: border-box;
            border-radius: 5px;
            margin: 0.3vw 0;
          }

          .RowLine {
            width: 90%;
            margin: 10px 0 10px 5%;
            height: 1px;
            background-color: rgba(#fff, 0.5);
          }

          .SubjCourse {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 3vw;
            font-size: 1vw;

            .Numbering {
              margin-right: 15px;
            }

            .SubjCourseDetail {
              display: flex;
              flex-direction: row;
              .btn {
                margin-left: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .CourseDetail {
    height: auto;
    width: 100vw;

    .InnerContainer {
      display: flex;
      flex-direction: column;
      padding: 100px 15vw;
      box-sizing: border-box;

      .wrap {
        display: flex;
        margin-top: 50px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .LeftSide {
          display: flex;
          flex-direction: row;
          width: 100%;

          justify-content: center;
          margin: 0 0 30px 0;
          .LeftInner {
            display: flex;
            flex-direction: row;

            .Numbering {
              font-size: 3.8vw;
              font-weight: bold;
              margin-right: 3vw;
            }

            .Desc {
              display: flex;
              flex-direction: column;

              .Title {
                font-size: 3.8vw;
                font-weight: bold;
              }
              .SubDesc {
                font-size: 2.6vw;
              }
            }
          }
        }

        .RightSide {
          .SubjTitle {
            display: flex;
            align-items: center;
            width: 70vw;
            font-size: 1.6vw;
            height: 5vw;
            background-color: rgba(#fff, 0.2);
            padding: 0 2vw;
            box-sizing: border-box;
            border-radius: 5px;
            margin: 1vw 0;
          }

          .SubjCourse {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            height: 4vw;
            font-size: 1.4vw;

            .Numbering {
              margin-right: 3vw;
            }

            .RowLine {
              width: 100%;
              height: 1px;
              background-color: rgba(#fff, 0.5);
            }

            .SubjCourseDetail {
              display: flex;
              align-items: center;

              .btn {
                margin-left: 2vw;
              }
            }
          }
        }
      }
    }
  }
}
@include mobile {
  .CourseDetail {
    height: auto;
    width: 100vw;
    min-height: calc(100vh - 30vw);

    .InnerContainer {
      display: flex;
      flex-direction: column;
      padding: 100px 10vw;
      box-sizing: border-box;

      .wrap {
        display: flex;
        margin-top: 50px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .LeftSide {
          display: flex;
          flex-direction: row;
          width: 100%;

          justify-content: center;
          margin: 0 0 30px 0;
          .LeftInner {
            display: flex;
            flex-direction: row;

            .Numbering {
              font-size: 3.8vw;
              font-weight: bold;
              margin-right: 3vw;
            }

            .Desc {
              display: flex;
              flex-direction: column;

              .Title {
                font-size: 3.8vw;
                font-weight: bold;
              }
              .SubDesc {
                font-size: 2.6vw;
              }
            }
          }
        }

        .RightSide {
          .SubjTitle {
            display: flex;
            align-items: center;
            width: 80vw;
            font-size: 1.6vw;
            height: 5vw;
            background-color: rgba(#fff, 0.2);
            padding: 0 2vw;
            box-sizing: border-box;
            border-radius: 5px;
            margin: 1vw 0;
          }

          .SubjCourse {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            height: 4vw;
            font-size: 1.4vw;

            .Numbering {
              margin-right: 3vw;
            }

            .RowLine {
              width: 100%;
              height: 1px;
              background-color: rgba(#fff, 0.5);
            }

            .SubjCourseDetail {
              display: flex;
              align-items: center;

              .btn {
                margin-left: 2vw;
                padding: 0.5vw 0.7vw;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }
}
