.LicenseSection {
  width: 100vw;
  height: 100vh;
  background-color: #17233a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .InnerContainer {
    .TitleContainer {
      display: flex;
      justify-content: center;
      span {
        font-size: 36px;
        color: #fff;
        font-weight: 500;
      }
    }

    .MenuContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      margin-top: 135px;

      .MenuBox {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 100%;
        font-weight: bold;
        color: #fff;
        width: 215px;
        height: auto;
        padding-bottom: 10px;
        cursor: pointer;
        transition: color 0.3s ease;
        border-bottom: 5px solid #fff;
        text-align: center;

        &.active {
          color: #f5a425;
        }
      }

      .underline {
        position: absolute;
        bottom: 0;
        width: 215px;
        height: 5px;
        background-color: #f5a425;
        transition: transform 0.3s ease;
      }
    }

    .CardContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 85px;
      flex-wrap: wrap;

      .FlipableCard {
        margin: 10px;
        height: 335px;
        width: 265px;
        cursor: pointer;
        &.HideCard {
          display: none;
        }

        .Inner {
          width: 100%;
          height: 100%;
          position: relative;
          transition: transform 0.8s;
          transform-style: preserve-3d;

          .FrontSide,
          .BackSide {
            position: relative;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            border-radius: 10px;
            background-color: #0c1228;

            &:hover {
              border: 1px solid #505464;
              border-radius: 5px;
            }
          }

          .FrontSide {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;

            img {
              width: 100px;
              height: 70px;
              margin-bottom: 50px;
            }

            .Title,
            .Description {
              color: #fff;
            }

            .Title {
              font-size: 18px;
              font-weight: bold;
            }

            .DescriptionBox {
              width: 100%;
              padding-left: 32px;
              padding-right: 32px;
              box-sizing: border-box;
              height: auto;

              .Title {
                margin-bottom: 0.5vw;
              }
              .Description {
                font-size: 12px;
              }
            }

            .Button {
              position: absolute;
              bottom: 20px;
              right: 25px;

              span {
                font-size: 12px;
                font-weight: bold;
                color: #f5a425;
              }
            }
          }

          .BackSide {
            transform: rotateY(180deg);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .Text {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;

              &.enFont {
                .Title {
                  font-size: 16px;
                  font-weight: bold;
                  text-align: center;
                }

                .Description {
                  font-size: 12px;
                  margin: 20px 15px 0 15px;
                  line-height: 15px;
                }
              }

              .Title,
              .Description {
                color: #fff;
              }

              .Title {
                font-size: 18px;
                font-weight: bold;
              }

              .Description {
                font-size: 11px;
                margin: 40px 32px 0 32px;
                line-height: 20px;
              }
            }

            .Button {
              position: absolute;
              bottom: 20px;
              right: 25px;

              span {
                font-size: 12px;
                font-weight: bold;
                color: #f5a425;
              }
            }
          }
        }

        .flipped {
          transform: rotateY(180deg);
        }
      }
    }
  }
}

@include laptop {
  .LicenseSection {
    .InnerContainer {
      .TitleContainer {
        display: flex;
        justify-content: center;
        span {
          font-size: 1.4vw;
          color: #fff;
          font-weight: 500;
        }
      }

      .MenuContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        margin-top: 3vw;

        .MenuBox {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.1vw;
          font-weight: bold;
          color: #fff;
          width: 13vw;
          height: auto;
          padding-bottom: 0.7vw;
          cursor: pointer;
          transition: color 0.3s ease;
          border-bottom: 5px solid #fff;
          text-align: center;

          &.active {
            color: #f5a425;
          }
        }

        .underline {
          position: absolute;
          bottom: 0;
          width: 13vw;
          height: 5px;
          background-color: #f5a425;
          transition: transform 0.3s ease;
        }
      }

      .CardContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 1.3vw;
        flex-wrap: wrap;

        .FlipableCard {
          margin: 0.3vw;
          height: 21vw;
          width: 15vw;
          cursor: pointer;

          .Inner {
            width: 100%;
            height: 100%;
            position: relative;
            transition: transform 0.8s;
            transform-style: preserve-3d;

            .FrontSide,
            .BackSide {
              position: relative;
              width: 100%;
              height: 100%;
              backface-visibility: hidden;
              border-radius: 10px;
              background-color: #0c1228;

              &:hover {
                border: 1px solid #505464;
                border-radius: 5px;
              }
            }

            .FrontSide {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: absolute;
              img {
                width: 8vw;
                height: 5vw;
                margin-bottom: 3vw;
              }

              .Title,
              .Description {
                color: #fff;
              }

              .Title {
                font-size: 1.1vw;
                font-weight: bold;
                margin-bottom: 0.2vw;
              }

              .DescriptionBox {
                width: 100%;
                padding: 0 1.8vw;
                box-sizing: border-box;

                .Description {
                  font-size: 0.8vw;
                  line-height: -2px;
                }
              }

              .Button {
                position: absolute;
                bottom: 20px;
                right: 25px;

                span {
                  font-size: 12px;
                  font-weight: bold;
                  color: #f5a425;
                }
              }
            }

            .BackSide {
              transform: rotateY(180deg);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: relative;

              .Text {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .Title,
                .Description {
                  color: #fff;
                }

                &.enFont {
                  .Title {
                    font-size: 0.9vw;
                    font-weight: bold;
                    text-align: center;
                  }

                  .Description {
                    font-size: 0.6vw;
                    margin: 1vw 1vw 0 1vw;
                    line-height: 1vw;
                  }
                }

                .Title {
                  font-size: 1.1vw;
                  font-weight: bold;
                }

                .Description {
                  font-size: 0.8vw;
                  margin: 2vw 1.5vw 0 1.5vw;
                  line-height: 1vw;
                }
              }

              .Button {
                position: absolute;
                bottom: 20px;
                right: 25px;

                span {
                  font-size: 12px;
                  font-weight: bold;
                  color: #f5a425;
                }
              }
            }
          }

          .flipped {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }
}

@include tablet {
  .LicenseSection {
    height: auto;
    padding: 50px 0px;

    .InnerContainer {
      .TitleContainer {
        span {
          font-size: 2.6vw;
          color: #fff;
          font-weight: 500;
        }
      }

      .MenuContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        margin-top: 5vw;

        .MenuBox {
          font-size: 1.4vw;
          width: 20vw;
          padding-bottom: 1vw;
        }

        .underline {
          width: 20vw;
        }
      }

      .CardContainer {
        display: grid;
        grid-template-columns: 50% 50%;
        flex-direction: row;
        justify-content: center;
        padding-top: 2vw;
        flex-wrap: wrap;

        .FlipableCard {
          margin: 1vw;
          height: 25vw;
          width: 30vw;

          .Inner {
            .FrontSide {
              img {
                width: 13vw;
                height: 9vw;
                margin-bottom: 3vw;
              }

              .Title {
                font-size: 1.7vw;
              }

              .DescriptionBox {
                display: flex;
                flex-direction: column;
                padding-left: 2vw;
                padding-right: 2vw;

                .Description {
                  font-size: 1.2vw;
                  padding-top: 1vw;
                }
              }
            }

            .BackSide {
              .Text {
                &.enFont {
                  .Title {
                    font-size: 1.5vw;
                    font-weight: bold;
                    text-align: center;
                  }

                  .Description {
                    font-size: 1.2vw;
                    margin: 2vw 1vw 0 1vw;
                    line-height: 1.3vw;
                  }
                }

                .Title {
                  font-size: 1.4vw;
                  margin-bottom: 0.2vw;

                  font-weight: bold;
                }

                .Description {
                  font-size: 1vw;
                  margin: 2vw 1.5vw 0 1.5vw;
                  line-height: 1.5vw;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include mobile {
  .LicenseSection {
    height: auto;
    padding: 50px 0px;

    .InnerContainer {
      .TitleContainer {
        span {
          font-size: 2.6vw;
          color: #fff;
          font-weight: 500;
        }
      }

      .MenuContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        margin-top: 5vw;

        .MenuBox {
          font-size: 1.6vw;
          width: 20vw;
          height: 3vw;
          padding-bottom: 1vw;
          border-bottom: 1px solid #fff;
        }

        .underline {
          width: 20vw;
          height: 1px;
        }
      }

      .CardContainer {
        display: grid;
        grid-template-columns: 50% 50%;
        flex-direction: row;
        justify-content: center;
        padding-top: 2vw;
        flex-wrap: wrap;

        .FlipableCard {
          margin: 1vw;
          height: 26vw;
          width: 35vw;

          .Inner {
            .FrontSide {
              img {
                width: 13vw;
                height: 9vw;
                margin-bottom: 3vw;
              }

              .Title {
                font-size: 1.7vw;
              }

              .DescriptionBox {
                display: flex;
                flex-direction: column;
                padding-left: 2vw;
                padding-right: 2vw;

                .Description {
                  font-size: 1.2vw;
                  padding-top: 1vw;
                }
              }
            }

            .BackSide {
              .Text {
                &.enFont {
                  .Title {
                    font-size: 1.5vw;
                    font-weight: bold;
                    text-align: center;
                  }

                  .Description {
                    font-size: 1.2vw;
                    margin: 2vw 1vw 0 1vw;
                    line-height: 1.3vw;
                  }
                }

                .Title {
                  font-size: 1.4vw;
                  font-weight: bold;
                }

                .Description {
                  font-size: 1vw;
                  margin: 2vw 1.5vw 0 1.5vw;
                  line-height: 1.5vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
