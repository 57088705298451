.LogoSection {
  width: 100vw;
  height: 100vh;
  background-color: #28354f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .LogoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;

    .Logo {
      filter: invert(100%);
      width: 180px;
      height: 180px;
    }

    .AcumenLab {
      width: 468px;
    }
  }

  .GoToLicense {
    display: flex;
    padding: 18px 34px;
    background-color: #f5a425;
    color: #fff;
    margin-top: 70px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
  }

  .LicenseDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0px;
    width: 1200px;

    .MobileDescriptionBox {
      display: none;
    }

    .HoverDiv {
      display: flex;
      flex-direction: row;
      .SlideBtn {
        height: 82px;

        .DescriptionWrapper {
          position: relative;
          bottom: 0px;
          width: 400px;
          transition: transform 0.3s, background-color 0.5s;
          background-color: #0c1228;

          .DescriptionBox {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            height: 82px;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
          }

          .HiddenBox {
            display: flex;
            justify-content: center;
            width: 400px;
            color: #fff;
            padding: 0px 45px 73px 45px;
            box-sizing: border-box;
            height: auto;
            font-size: 14px;

            &:after {
              content: "";
              position: absolute;
              bottom: 50px;
              width: 70px;
              border: 1px solid #fff;
            }
          }
        }
        &:hover .DescriptionWrapper {
          transform: translateY(-65%);
          background-color: #f5a425;
        }
      }

      .SlideBtn.active .DescriptionWrapper {
        background-color: #f5a425;
      }
    }
  }
}

@include laptop {
  .LogoSection {
    width: 100vw;
    height: 100vh;
    background-color: #28354f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .LogoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 18px;

      .Logo {
        filter: invert(100%);
        width: 180px;
        height: 180px;
      }

      .AcumenLab {
        width: 468px;
      }
    }

    .GoToLicense {
      display: flex;
      padding: 18px 34px;
      background-color: #f5a425;
      color: #fff;
      margin-top: 70px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
    }

    .LicenseDescription {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0px;
      width: 1200px;

      .MobileDescriptionBox {
        display: none;
      }

      .HoverDiv {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        .SlideBtn {
          height: 82px;

          .DescriptionWrapper {
            position: relative;
            bottom: 0px;
            width: 30vw;
            transition: transform 0.3s, background-color 0.5s;
            background-color: #0c1228;

            .DescriptionBox {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              height: 82px;
              font-size: 18px;
              font-weight: bold;
              text-align: center;
            }

            .HiddenBox {
              display: flex;
              justify-content: center;
              width: 30vw;

              color: #fff;
              padding: 0px 45px 73px 45px;
              box-sizing: border-box;
              height: auto;
              font-size: 14px;

              &:after {
                content: "";
                position: absolute;
                bottom: 50px;
                width: 70px;
                border: 1px solid #fff;
              }
            }
          }
          &:hover .DescriptionWrapper {
            transform: translateY(-65%);
            background-color: #f5a425;
          }
        }

        .SlideBtn.active .DescriptionWrapper {
          background-color: #f5a425;
        }
      }
    }
  }
}

@include tablet {
  .LogoSection {
    .LogoContainer {
      .Logo {
        width: 20vw;
        height: 20vw;
      }

      .AcumenLab {
        width: 30%;
      }
    }

    .GoToLicense {
      font-size: 3vw;
    }

    .LicenseDescription {
      display: flex;
      width: 80%;

      .MobileDescriptionBox {
        display: flex;
        position: relative;
        background-color: #f5a425;
        width: 100%;
        height: auto;
        font-size: 2vw;
        color: #fff;
        padding: 30px;
        box-sizing: border-box;

        &.hidden {
          display: none;
        }
      }

      .HoverDiv {
        .SlideBtn {
          width: 33.3%;
          &:hover .DescriptionWrapper {
            transform: translateY(0%);
            background-color: #f5a425;
          }

          .DescriptionWrapper {
            width: 100%;

            .DescriptionBox {
              font-size: 2vw;
            }

            .HiddenBox {
              width: auto;
              padding: 0px 20px 70px 20px;
              font-size: 1.7vw;

              &:after {
                border: none;
              }
            }
          }
        }

        .SlideBtn.active .DescriptionWrapper {
          background-color: #f5a425;
          border-top: none;
        }
      }
    }
  }
}

@include mobile {
  .LogoSection {
    .LogoContainer {
      .Logo {
        width: 30vw;
        height: 30vw;
      }

      .AcumenLab {
        width: 50%;
      }
    }

    .GoToLicense {
      font-size: 3vw;
      padding: 3vw;
      margin-top: 8vw;
    }

    .LicenseDescription {
      display: flex;
      width: 100%;

      .MobileDescriptionBox {
        display: flex;
        position: relative;
        background-color: #f5a425;
        width: 100%;
        height: auto;
        font-size: 2.5vw;
        color: #fff;
        padding: 30px;
        box-sizing: border-box;

        &.hidden {
          display: none;
        }
      }

      .HoverDiv {
        .SlideBtn {
          width: 33.3%;
          &:hover .DescriptionWrapper {
            transform: translateY(0%);
            background-color: #f5a425;
          }

          .DescriptionWrapper {
            width: 100%;

            .DescriptionBox {
              font-size: 2vw;
            }

            .HiddenBox {
              width: auto;
              padding: 0px 20px 70px 20px;
              font-size: 1.7vw;

              &:after {
                border: none;
              }
            }
          }
        }

        .SlideBtn.active .DescriptionWrapper {
          background-color: #f5a425;
          border-top: none;
        }
      }
    }
  }
}
