.Course {
  height: auto;
  width: 100vw;
  background-color: #0c1228;

  .InnerContainer {
    display: flex;
    flex-direction: column;
    padding: 100px 250px;
    box-sizing: border-box;

    // .Breadcrumb {
    //   margin-left: 8vw;
    // }

    .SearchContainer {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin: 31px 0 30px 0;

      .SearchBar {
        display: flex;
        flex-direction: row;
        width: 250px;
        border-bottom: 1px solid #fff;
        align-items: center;

        img {
          height: 18px;
          margin-right: 10px;
        }

        input {
          background-color: transparent;
          border: none;
          margin-bottom: 5px;
          font-size: 14px;
          color: #fff;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .TopTitle {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: #fff;
        font-size: 36px;
        font-weight: 500;
      }
    }

    .CourseOuter {
      display: flex;
      width: 100%;
      justify-content: center;

      .Courses {
        width: inherit;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: #fff;

        .CourseContainer {
          width: 30%;
          height: auto;
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          flex-shrink: 0;

          .ThumbnailBox {
            width: 100%;
            height: 220px;
            background-color: gray;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            img {
              width: 100%;
              height: 100%;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }
          }

          .DescBox {
            width: 100%;
            height: 230px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 30px 35px;
            position: relative;
            box-sizing: border-box;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            color: #fff;
            background-color: rgba($color: #fff, $alpha: 0.1);
            border: 1px solid rgba($color: #fff, $alpha: 0.2);

            &.enFont {
              .UpperDesc {
                .DescTitle {
                  font-size: 16px;
                }

                .DescDesc {
                  font-size: 12px;
                  white-space: nowrap;

                  .Middle {
                    padding: 0 8px;
                  }
                }
              }

              .UnderDesc {
                gap: 6px;

                .SubjectDesc {
                  font-size: 12px;

                  .Middle {
                    padding: 0 5px;
                  }
                }
              }
            }

            .UpperDesc {
              display: flex;
              justify-content: space-between;
              color: #e0edff;
              max-width: 100%;
              .DescTitle {
                font-size: 24px;
                font-weight: bold;
                max-width: 70%;
              }

              .DescDesc {
                font-size: 15px;

                .Middle {
                  padding: 0 10px;
                }
              }
            }

            .UnderDesc {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .SubjectDesc {
                font-size: 15px;

                .Bold {
                  font-weight: bold;
                }
                .Middle {
                  padding: 0 5px;
                }
              }
            }

            .DescBtn {
              position: absolute;
              bottom: 20px;
              right: 20px;
              border-radius: 5px;
              padding: 5px 10px;
              background-color: #f5a425;
              cursor: pointer;

              img {
                vertical-align: middle;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@include laptop {
  .Course {
    .InnerContainer {
      display: flex;
      flex-direction: column;
      padding: 100px 150px;
      box-sizing: border-box;

      .Breadcrumb {
        margin-left: 1vw;
      }

      .SearchContainer {
        display: flex;
        width: 99%;
        justify-content: flex-end;
        margin: 31px 0 30px 0;

        .SearchBar {
          display: flex;
          flex-direction: row;
          width: 250px;
          border-bottom: 1px solid #fff;
          align-items: center;

          img {
            height: 18px;
            margin-right: 10px;
          }

          input {
            background-color: transparent;
            border: none;
            margin-bottom: 5px;
            font-size: 14px;
            color: #fff;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .TopTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: #fff;
          font-size: 1.6vw;
          font-weight: 500;
        }
      }

      .CourseOuter {
        .Courses {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .CourseContainer {
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0.5vw;

            .ThumbnailBox {
              width: 100%;
              height: 12vw;
              background-color: gray;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              img {
                width: 100%;
                height: 100%;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
              }
            }

            .DescBox {
              width: 100%;
              height: 12vw;
              display: flex;
              flex-direction: column;
              gap: 0.5vw;
              padding: 1.5vw;
              position: relative;
              box-sizing: border-box;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              color: #fff;
              background-color: rgba($color: #fff, $alpha: 0.1);
              border: 1px solid rgba($color: #fff, $alpha: 0.2);

              &.enFont {
                .UpperDesc {
                  .DescTitle {
                    font-size: 1vw;
                  }

                  .DescDesc {
                    font-size: 0.55vw;
                    white-space: nowrap;

                    .Middle {
                      padding: 0 8px;
                    }
                  }
                }

                .UnderDesc {
                  gap: 0.1vw;

                  .SubjectDesc {
                    font-size: 0.7vw;

                    .Middle {
                      padding: 0 5px;
                    }
                  }
                }
              }

              .UpperDesc {
                display: flex;
                justify-content: space-between;
                color: #e0edff;
                gap: 0.1vw;
                .DescTitle {
                  font-size: 1.4vw;
                  font-weight: bold;
                }

                .DescDesc {
                  padding: 0.1vw 0;
                  vertical-align: bottom;
                  font-size: 0.4vw;

                  .Middle {
                    padding: 0 0.3vw;
                  }
                }
              }

              .UnderDesc {
                display: flex;
                flex-direction: column;
                gap: 0.2vw;

                .SubjectDesc {
                  font-size: 0.85vw;

                  .Bold {
                    font-weight: bold;
                  }
                  .Middle {
                    padding: 0 0.3vw;
                  }
                }
              }

              .DescBtn {
                position: absolute;
                bottom: 1vw;
                right: 1vw;
                white-space: nowrap;
                font-size: 0.7vw;

                img {
                  margin-right: 0.4vw;
                  width: 0.7vw;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
@include tablet {
  .Course {
    .InnerContainer {
      .Breadcrumb {
        margin-left: 6vw;
      }
      display: flex;
      flex-direction: column;
      padding: 100px 10px;
      box-sizing: border-box;

      .SearchContainer {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 31px 0 30px 0;

        .SearchBar {
          display: flex;
          flex-direction: row;
          width: 85%;
          border-bottom: 1px solid #fff;
          align-items: center;

          img {
            height: 1.3vw;
            margin-right: 1vw;
          }

          input {
            background-color: transparent;
            border: none;
            margin-bottom: 0.8vw;
            font-size: 1.1vw;
            color: #fff;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .TopTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: #fff;
          font-size: 1.8vw;
          font-weight: 500;
        }
      }

      .CourseOuter {
        .Courses {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 0.5vw;
          padding: 0 5vw;

          .CourseContainer {
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0.5vw;

            .ThumbnailBox {
              width: 100%;
              height: 15vw;
              background-color: gray;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              img {
                width: 100%;
                height: 100%;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
              }
            }

            .DescBox {
              width: 100%;
              height: 16vw;
              display: flex;
              flex-direction: column;
              gap: 0.6vw;
              padding: 1.5vw;
              position: relative;
              box-sizing: border-box;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              color: #fff;
              background-color: rgba($color: #fff, $alpha: 0.1);
              border: 1px solid rgba($color: #fff, $alpha: 0.2);

              &.enFont {
                .UpperDesc {
                  .DescTitle {
                    font-size: 1.2vw;
                  }

                  .DescDesc {
                    font-size: 0.85vw;
                    white-space: nowrap;

                    .Middle {
                      padding: 0 8px;
                    }
                  }
                }

                .UnderDesc {
                  gap: 0.5vw;

                  .SubjectDesc {
                    font-size: 0.9vw;

                    .Middle {
                      padding: 0 5px;
                    }
                  }
                }
              }

              .UpperDesc {
                display: flex;
                justify-content: space-between;
                color: #e0edff;
                .DescTitle {
                  font-size: 1.6vw;
                  font-weight: bold;
                }

                .DescDesc {
                  padding: 0.2vw 0;
                  vertical-align: bottom;
                  font-size: 1vw;
                  white-space: nowrap;

                  .Middle {
                    padding: 0 0.3vw;
                  }
                }
              }

              .UnderDesc {
                display: flex;
                flex-direction: column;
                gap: 0.2vw;

                .SubjectDesc {
                  font-size: 1.2vw;

                  .Bold {
                    font-weight: bold;
                  }
                  .Middle {
                    padding: 0 0.3vw;
                  }
                }
              }

              .DescBtn {
                position: absolute;
                bottom: 0.6vw;
                right: 1vw;
                white-space: nowrap;
                font-size: 0.9vw;
                padding: 0.4vw 0.8vw;

                img {
                  margin-right: 0.4vw;
                  width: 0.7vw;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include mobile {
  .Course {
    .InnerContainer {
      display: flex;
      flex-direction: column;
      padding: 100px 10px;
      box-sizing: border-box;
      .Breadcrumb {
        margin-left: 12vw;
        margin-bottom: 10vw;
      }

      .SearchContainer {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 31px 0 30px 0;

        .SearchBar {
          display: flex;
          flex-direction: row;
          width: 75%;
          border-bottom: 1px solid #fff;
          align-items: center;

          img {
            height: 5vw;
            margin-right: 2vw;
          }

          input {
            background-color: transparent;
            border: none;
            margin-bottom: 1.3vw;
            font-size: 3vw;
            color: #fff;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .TopTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: #fff;
          font-size: 5vw;
          font-weight: 500;
        }
      }

      .CourseOuter {
        .Courses {
          width: 80%;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          color: #fff;
          gap: 15px;

          .CourseContainer {
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0.5vw;
            width: 100%;

            .ThumbnailBox {
              width: 100%;
              height: 42vw;
              background-color: gray;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              img {
                width: 100%;
                height: 100%;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
              }
            }

            .DescBox {
              width: 100%;
              height: 42vw;
              display: flex;
              flex-direction: column;
              gap: 0.5vw;
              padding: 3vw;
              position: relative;
              box-sizing: border-box;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              color: #fff;
              background-color: rgba($color: #fff, $alpha: 0.1);
              border: 1px solid rgba($color: #fff, $alpha: 0.2);
              &.enFont {
                .UpperDesc {
                  .DescTitle {
                    font-size: 3.5vw;
                  }

                  .DescDesc {
                    font-size: 2.5vw;
                    white-space: nowrap;

                    .Middle {
                      padding: 0 8px;
                    }
                  }
                }

                .UnderDesc {
                  gap: 0.5vw;

                  .SubjectDesc {
                    font-size: 2.5vw;

                    .Middle {
                      padding: 0 5px;
                    }
                  }
                }
              }

              .UpperDesc {
                display: flex;
                justify-content: space-between;
                color: #e0edff;
                .DescTitle {
                  font-size: 4.5vw;
                  font-weight: bold;
                }

                .DescDesc {
                  padding: 0;
                  vertical-align: bottom;
                  font-size: 3vw;

                  .Middle {
                    padding: 0 1vw;
                  }
                }
              }

              .UnderDesc {
                display: flex;
                flex-direction: column;
                gap: 1.5vw;
                margin-top: 2vw;

                .SubjectDesc {
                  font-size: 3vw;

                  .Bold {
                    font-weight: bold;
                  }
                  .Middle {
                    padding: 0 1vw;
                  }
                }
              }

              .DescBtn {
                position: absolute;
                bottom: 2vw;
                right: 2vw;
                white-space: nowrap;
                font-size: 2.5vw;

                img {
                  margin-right: 2vw;
                  width: 3vw;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
