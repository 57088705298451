.DescriptionSection {
  margin: 0 auto;
  height: 100vh;
  background-color: #17233a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 160px;

  .InnerContainer {
    .LogoContainer {
      img {
        width: 281px;
      }
    }

    .DescriptionContainer {
      padding-top: 105px;

      span {
        font-size: 20px;
        color: #fff;
        line-height: 30px;
      }
    }
  }
}

@include laptop {
  .DescriptionSection {
    padding: 0px 15vw;

    .InnerContainer {
      .LogoContainer {
        img {
          width: 15vw;
        }
      }

      .DescriptionContainer {
        padding-top: 4vw;

        span {
          font-size: 1.2vw;
          line-height: 1.3vw;
        }
      }
    }
  }
}

@include tablet {
  .DescriptionSection {
    padding: 0px 80px;

    .InnerContainer {
      .LogoContainer {
        img {
          width: 200px;
        }
      }

      .DescriptionContainer {
        padding-top: 7vh;

        span {
          font-size: 1.7vw;
          line-height: 2vw;
        }
      }
    }
  }
}

@include mobile {
  .DescriptionSection {
    padding: 0px 30px;

    .InnerContainer {
      .LogoContainer {
        img {
          width: 30vw;
        }
      }

      .DescriptionContainer {
        padding-top: 5vw;

        span {
          font-size: 2.2vw;
          line-height: 2vw;
        }
      }
    }
  }
}
