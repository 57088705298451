@mixin LicenseStyle {
  .Licenses {
    display: flex;
    flex-direction: row;
    margin-bottom: 100px;
    justify-content: space-between;

    .LicenseBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #0c1228;
      min-width: 273px;
      max-width: 273px;
      min-height: 164px;
      border-radius: 5px;
      margin: 0 10px;
      border: 1px solid transparent;
      cursor: pointer;

      &:hover {
        border-style: inset;
        border: 1px solid #505464;
      }

      .Title {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        margin-bottom: 0.4vw;
      }
      .Desc {
        font-size: 10px;
        color: #aaaaaa;
        text-align: center;
      }
    }

    .EmptyBox {
      display: hidden;
      min-width: 273px;
      min-height: 164px;
    }
  }
}

@mixin SmallOrangeBtnStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 72px;
  height: 27px;
  font-size: 14px;
  font-weight: bold;
  background-color: #f5a425;
  box-shadow: 0px 1px 1px 1px #000;
  cursor: pointer;
}
