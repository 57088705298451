@import "./variables.scss";

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile} ) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-mobile+1px}) and (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$breakpoint-tablet+1px}) and (max-width: #{$breakpoint-laptop}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-laptop+1px}) {
    @content;
  }
}
